import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { tabletS, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Card from '@/components/common/Card'

const OutdoorList = props => {
  return (
    <Root>
      <Main>
        <List>
          <List_Item
            title={(
              <Fragment>製品開発・販売</Fragment>
            )}
            body={(
              <Fragment>アウトドア用品の企画・開発・製造・販売を行っています。「物」ではなく、アウトドアシーンで生まれた「課題」から製品を企画することを心がけ、自分たちの考え方を形にするために製品を作っています。</Fragment>
            )}
            link={{
              href: 'https://store.maagz.jp/',
              text: '詳しくはこちら'
            }}
          />
          <List_Item
            title={(
              <Fragment>メディア運営</Fragment>
            )}
            body={(
              <Fragment>アウトドア業界全体が継続的に発展するための循環を作ることを目的とした、体験・知識・考え方など、学びや発見のある情報を発信するWEBメディアを運用しています。目指すのはキャンプのポータルサイト。今後さまざまなコンテンツを用意し、アウトドアに興味を持つ多くの読者にその楽しさと、役立つ情報をお届けします。</Fragment>
            )}
            link={{
              href: 'https://maagz.jp/',
              text: '詳しくはこちら'
            }}
          />
        </List>
      </Main>
    </Root>
  )
}

const Root = styled.div``

const Main = styled.div``

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const List_Item = styled(Card)`
  width: 50%;
  @media ${mq.and(tabletS, mobile)} {
    width: 100%;
  }
`

export default ThemeConsumer(OutdoorList)
