export const Corporation = (siteMeta, company) => {
  return {
    '@type': 'Corporation',
    ...company,
    logo: `${siteMeta.siteUrl}/images/icon.png`,
    image: `${siteMeta.siteUrl}/images/ogp.jpg`,
    url: `${siteMeta.siteUrl}/`
  }
}

export const Organization = (siteMeta, company) => {
  return {
    '@type': 'Organization',
    name: company.name,
    logo: `${siteMeta.siteUrl}/images/icon.png`,
    sameAs: `${siteMeta.siteUrl}/`
  }
}

export default {
  Corporation, Organization
}
